import { zodResolver } from '@hookform/resolvers/zod'
import { EnterIcon, ExclamationTriangleIcon, QuestionMarkCircledIcon } from '@radix-ui/react-icons'
import { json, MetaFunction, redirect } from '@remix-run/node'
import type { ActionFunctionArgs, LoaderFunctionArgs } from '@remix-run/node'
import { Link } from '@remix-run/react'
import { getCurrentUser, signIn, signOut } from 'aws-amplify/auth'
import { RemixFormProvider, useRemixForm } from 'remix-hook-form'
import { z } from 'zod'
import { getSession, commitSession, isAuthenticated, sessionOrganizationIdKey, sessionUserIdKey } from '~/auth.server'
import { Alert, AlertTitle } from '~/components/ui/alert'
import { AuthLayout } from '~/components/ui/auth-layout'
import { Button } from '~/components/ui/button'
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from '~/components/ui/form'
import { Input } from '~/components/ui/input'
import { cn, parseRootErrorMessage } from '~/lib/utils'
import { getValidatedFormJson, jsonActionUnprocessableContentResponse } from '~/lib/utils.server'
import { getUserByEmail } from '~/models/user.server'

const FormSchema = z.object({
  email: z.string().email('Please use valid email'),
  password: z.string().min(1, {
    message: 'Password is required',
  }),
})

const resolver = zodResolver(FormSchema)

type FormData = z.infer<typeof FormSchema>

export async function loader({ request }: LoaderFunctionArgs) {
  if (await isAuthenticated(request)) {
    return redirect('/')
  }

  return json({})
}

export async function action({ request }: ActionFunctionArgs) {
  const session = await getSession(request.headers.get('Cookie'))

  const { errors, data } = await getValidatedFormJson<FormData>(request, resolver)

  if (errors) {
    return jsonActionUnprocessableContentResponse({
      success: false,
      errors,
    })
  }

  await signOut()

  try {
    const signInResponse = await signIn({ username: data.email.toString(), password: data.password.toString() })

    if (signInResponse.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
      return redirect('/confirm-login')
    }
  } catch (e) {
    return jsonActionUnprocessableContentResponse({
      success: false,
      errors: {
        root: {
          formError: {
            message: (e as Error).message,
            type: z.ZodIssueCode.custom,
          },
        },
      },
    })
  }

  let currentBearUser
  try {
    const currentCognitoUser = await getCurrentUser()
    const email = currentCognitoUser.signInDetails?.loginId
    if (!email) {
      throw new Error('Could not confirm login: cognito user missing loginId')
    }
    currentBearUser = await getUserByEmail(email)
  } catch (e) {
    return jsonActionUnprocessableContentResponse({
      success: false,
      errors: {
        root: {
          formError: {
            message: 'Invalid email/password',
            type: z.ZodIssueCode.custom,
          },
        },
      },
    })
  }

  session.set(sessionUserIdKey, currentBearUser.id)
  if (currentBearUser.organizations.length > 0) {
    session.set(sessionOrganizationIdKey, currentBearUser.organizations[0].organization.id)
  }

  return redirect('/', {
    headers: {
      'Set-Cookie': await commitSession(session),
    },
  })
}

export const meta: MetaFunction = () => {
  return [{ title: 'Login | CommerceBear' }]
}

export default function LoginForm() {
  const remixForm = useRemixForm<FormData>({
    mode: 'onChange',
    resolver,
    defaultValues: {
      email: '',
      password: '',
    },
    submitConfig: {
      encType: 'application/json',
    },
  })
  const rootErrorMessage = parseRootErrorMessage(remixForm.formState.errors)
  const { isValid } = remixForm.formState

  return (
    <AuthLayout
      title={
        <>
          WELCOME
          <br />
          HOME.
        </>
      }
    >
      <RemixFormProvider {...remixForm}>
        <form method="POST" onSubmit={remixForm.handleSubmit} className="w-full">
          <div className="grid gap-6">
            <div className="grid gap-4">
              {rootErrorMessage && (
                <Alert variant="destructive">
                  <ExclamationTriangleIcon className="h-4 w-4" />
                  <AlertTitle>{rootErrorMessage}</AlertTitle>
                </Alert>
              )}
              <FormField
                control={remixForm.control}
                name="email"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input placeholder="Email" type="email" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
              <FormField
                control={remixForm.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <div className="flex justify-between items-center">
                      <FormLabel>Password</FormLabel>
                      <Link to="/forgot-password" className={cn('flex items-center', 'ml-auto text-sm underline')}>
                        Forgot your password <QuestionMarkCircledIcon className="ml-2 h-4 w-4" />
                      </Link>
                    </div>
                    <FormControl>
                      <Input placeholder="Password" type="password" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex justify-end">
              <Button type="submit" disabled={!isValid}>
                Login <EnterIcon className="ml-2 h-4 w-4" />
              </Button>
            </div>
          </div>
        </form>
      </RemixFormProvider>
    </AuthLayout>
  )
}
